import dataTableFooter from "@/calendesk/prototypes/dataTableFooter";

export default {
  headers: {
    type: Array,
    default: null,
  },
  action: {
    type: String,
    default: null,
  },
  onSelectActions: {
    type: Array,
    default: null,
  },
  selectionMap: {
    type: Function,
    default: null,
  },
  filters: {
    type: Object,
    default: null,
    required: false,
  },
  selectedFilters: {
    type: Array,
    default: () => [],
  },
  selectedGroupIds: {
    type: Array,
    default: () => [],
  },
  search: {
    type: String,
    default: "",
  },
  cleanSortableValue: {
    type: String,
    default: null,
  },
  defaultSortBy: {
    type: Array,
    default: () => ["id"],
  },
  showSelect: {
    type: Boolean,
    default: false,
  },
  hideElements: {
    type: Boolean,
    default: false,
  },
  actionsExpanded: {
    type: Boolean,
    default: false,
  },
  isLoadingInSelectMenu: {
    type: Boolean,
    default: false,
  },
  footerProps: {
    type: Object,
    default: () => dataTableFooter,
  },
  hideDefaultFooter: {
    type: Boolean,
    default: false,
  },
};
