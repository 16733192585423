var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"custom-table",attrs:{"footer-props":_vm.footerProps,"header-props":_vm.hideElements ? {} : _vm.headerProps,"headers":_vm.hideElements ? [] : _vm.headers,"items":_vm.fetchData.data,"loading":_vm.isLoadingTable,"no-results-text":_vm.$trans('nothing_found_here'),"no-data-text":_vm.$trans('nothing_found_here'),"loading-text":_vm.$trans('loading'),"search":_vm.search,"options":_vm.tableOptions,"server-items-length":_vm.fetchData.total,"show-select":_vm.hideElements ? false : _vm.showSelect,"hide-default-footer":_vm.hideDefaultFooter || _vm.hideElements},on:{"update:search":function($event){_vm.search=$event},"update:options":function($event){_vm.tableOptions=$event},"click:row":_vm.onTableRowClick},scopedSlots:_vm._u([{key:"item.issue_date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm._f("moment")(item.issue_date,_vm.$helpers.dayMonthAndYearDate))+" ")])]}},{key:"item.price_gross",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"75px"}},[_vm._v(" "+_vm._s(_vm._f("money")(item.price_gross, _vm.adminConfiguration ? _vm.adminConfiguration.tenant_currency : null ))+" ")])]}},{key:"item.invoice_pdf",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","fab":"","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('downloadInvoice', item)}}},[_c('v-icon',[_vm._v("$download")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.actionsExpanded)?_c('span',[_c('div',{staticClass:"d-inline-block"},_vm._l((_vm.onSelectActions),function(el,i){return _c('v-btn',{key:i,attrs:{"color":"light","fab":"","text":"","small":""},on:{"click":function($event){el.action && el.action(item, el.target)}}},[_c('v-icon',{domProps:{"textContent":_vm._s(el.icon)}})],1)}),1)]):_c('v-menu',{attrs:{"offset-x":"","close-on-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""}},on),[_c('v-icon',[_vm._v(" $dots-vertical")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.onSelectActions),function(el,i){return _c('v-list-item',{key:i,on:{"click":function($event){el.action && el.action([item].map(_vm.selectionMap), el.target)}}},[_c('v-list-item-title',{class:el.class ? el.class : ''},[_vm._v(" "+_vm._s(_vm.$trans(el.title.single))+" ")])],1)}),1)],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),(_vm.selected.length)?_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('div',{staticClass:"text-body-1 card-position"},[_c('v-menu',{attrs:{"top":"","offset-x":"","close-on-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-3",attrs:{"icon":"","small":""}},on),[_c('v-icon',[_vm._v(" $dots-vertical")])],1),_vm._v(" "+_vm._s(_vm.$trans("selected_n_positions", { amount: _vm.selected.length }))+" ")]}}],null,false,3368183081)},[_c('v-list',_vm._l((_vm.onSelectActions),function(el,i){return _c('div',{key:i},[(!el.hideOnMultiple)?_c('v-list-item',{on:{"click":function($event){el.action && el.action(_vm.selectedToSend, el.target, el.params)}}},[_c('v-list-item-title',{staticClass:"d-flex align-center",class:el.class ? el.class : ''},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":el.color}},[_vm._v(" "+_vm._s(el.icon)+" ")]),_vm._v(" "+_vm._s(_vm.$trans(el.title.many, { amount: _vm.selected.length }))+" ")],1)],1):_vm._e()],1)}),0)],1),(_vm.isLoadingInSelectMenu)?_c('v-progress-circular',{staticClass:"ml-4",attrs:{"indeterminate":"","color":"primary","size":"24"}}):_vm._e()],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }