<template>
  <div>
    <v-data-table
      v-model="selected"
      class="custom-table"
      :footer-props="footerProps"
      :header-props="hideElements ? {} : headerProps"
      :headers="hideElements ? [] : headers"
      :items="fetchData.data"
      :loading="isLoadingTable"
      :no-results-text="$trans('nothing_found_here')"
      :no-data-text="$trans('nothing_found_here')"
      :loading-text="$trans('loading')"
      :search.sync="search"
      :options.sync="tableOptions"
      :server-items-length="fetchData.total"
      :show-select="hideElements ? false : showSelect"
      :hide-default-footer="hideDefaultFooter || hideElements"
      @click:row="onTableRowClick"
    >
      <template #[`item.issue_date`]="{ item }">
        <div class="text-no-wrap">
          {{ item.issue_date | moment($helpers.dayMonthAndYearDate) }}
        </div>
      </template>
      <template #[`item.price_gross`]="{ item }">
        <div style="min-width: 75px">
          {{
            item.price_gross
              | money(
                adminConfiguration ? adminConfiguration.tenant_currency : null
              )
          }}
        </div>
      </template>
      <template #[`item.invoice_pdf`]="{ item }">
        <v-btn icon fab text @click.stop="$emit('downloadInvoice', item)">
          <v-icon>$download</v-icon>
        </v-btn>
      </template>
      <template #[`item.actions`]="{ item }">
        <span v-if="actionsExpanded">
          <div class="d-inline-block">
            <v-btn
              v-for="(el, i) in onSelectActions"
              :key="i"
              color="light"
              fab
              text
              small
              @click="el.action && el.action(item, el.target)"
            >
              <v-icon v-text="el.icon" />
            </v-btn>
          </div>
        </span>
        <v-menu v-else offset-x :close-on-click="true">
          <template #activator="{ on }">
            <v-btn icon small v-on="on">
              <v-icon> $dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(el, i) in onSelectActions"
              :key="i"
              @click="
                el.action && el.action([item].map(selectionMap), el.target)
              "
            >
              <v-list-item-title :class="el.class ? el.class : ''">
                {{ $trans(el.title.single) }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
    <v-card v-if="selected.length" outlined>
      <v-card-text>
        <div class="text-body-1 card-position">
          <v-menu top offset-x :close-on-click="true">
            <template #activator="{ on }">
              <v-btn class="mr-3" icon small v-on="on">
                <v-icon> $dots-vertical</v-icon>
              </v-btn>
              {{ $trans("selected_n_positions", { amount: selected.length }) }}
            </template>
            <v-list>
              <div v-for="(el, i) in onSelectActions" :key="i">
                <v-list-item
                  v-if="!el.hideOnMultiple"
                  @click="
                    el.action && el.action(selectedToSend, el.target, el.params)
                  "
                >
                  <v-list-item-title
                    :class="el.class ? el.class : ''"
                    class="d-flex align-center"
                  >
                    <v-icon class="mr-2" :color="el.color">
                      {{ el.icon }}
                    </v-icon>
                    {{ $trans(el.title.many, { amount: selected.length }) }}
                  </v-list-item-title>
                </v-list-item>
              </div>
            </v-list>
          </v-menu>
          <v-progress-circular
            v-if="isLoadingInSelectMenu"
            indeterminate
            color="primary"
            size="24"
            class="ml-4"
          />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { debounce } from "debounce";
import props from "./CDatatable.props.js";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import dataTableService from "@/calendesk/mixins/dataTableService";
import roleActions from "@/calendesk/mixins/roleActions";
import planActions from "@/calendesk/mixins/planActions";
import cacheActions from "@/calendesk/mixins/cacheActions";

export default {
  name: "CDataTable",
  mixins: [dataTableService, roleActions, planActions, cacheActions],
  props,
  data() {
    return {
      tableOptions: {
        itemsPerPage: 25,
        sortBy: this.defaultSortBy,
        sortDesc: [true],
        page: 1,
      },
      headerProps: {
        sortByText: this.$trans("sort_by"),
      },
      fetchData: {
        data: [],
        total: null,
        from: 1,
        to: 1,
      },
      selected: [],
      isLoadingTable: false,
    };
  },
  computed: {
    ...mapGetters({
      refreshSubscriptionsListStatus:
        "subscriptions/getRefreshSubscriptionsListStatus",
      refreshSchedulesList:
        "availabilitySchedule/getRefreshSchedulesListStatus",
      refreshUsersStatus: "user/shouldRefreshUsers",
      refreshTagsStatus: "tag/shouldRefreshTags",
      refreshResourcesStatus: "resource/shouldRefreshResources",
      refreshGroupsStatus: "group/shouldRefreshGroups",
      refreshNewsletterStatus: "newsletter/getRefreshNewsletterStatus",
      refreshSimpleStoreProductList:
        "simpleStore/getRefreshSimpleStoreProductListStatus",
      getCachedTableOptions: "cache/getTableOptions",
      adminConfiguration: "setup/getAdminConfiguration",
    }),
    route() {
      return this.$route.name;
    },
    selectedToSend() {
      return this.selected.map(this.selectionMap);
    },
  },
  watch: {
    refreshTagsStatus(val) {
      val && this.refreshTable();
    },
    refreshResourcesStatus(val) {
      val && this.refreshTable();
    },
    refreshGroupsStatus(val) {
      val && this.refreshTable();
    },
    tableOptions() {
      this.cacheTableOptions();
      this.load("debounce");
    },
  },
  created() {
    this.restoreTableOptions();
    this.load();

    this.$watch("filters", this.filtersChanged);
    this.$watch("selectedFilters", this.filtersChanged);
    this.$watch("selectedGroupIds", this.filtersChanged);
    this.$watch("search", this.filtersChanged);
  },
  methods: {
    ...mapActions({
      setTableOptions: "cache/setTableOptions",
    }),
    filtersChanged() {
      this.tableOptions.page = 1;
      this.load("debounce");
    },
    cacheTableOptions() {
      if (this.tableOptions) {
        this.setTableOptions({
          cacheId: this.action,
          data: this.$helpers.cloneObject(this.tableOptions),
        });
      }
    },
    restoreTableOptions() {
      if (
        this.getCachedTableOptions &&
        this.getCachedTableOptions[this.action]
      ) {
        this.tableOptions = this.$helpers.cloneObject(
          this.getCachedTableOptions[this.action]
        );
      }
    },
    onTableRowClick(item) {
      this.$emit("onRowClick", item);
    },
    loadingTable(val) {
      this.isLoadingTable = val;
      this.$emit("loading", val);
    },
    async loadData(ignoreCache) {
      const dataOptions = this.getQueryOptions();

      if (!ignoreCache) {
        const cachedTableData = await this.getCachedTableData(this.action);

        if (cachedTableData) {
          const cachedOptions = cachedTableData.options;

          if (cachedOptions) {
            if (JSON.stringify(dataOptions) === JSON.stringify(cachedOptions)) {
              this.fetchData = cachedTableData.data;
              return true;
            }
          }
        }
      }

      try {
        this.loadingTable(true);
        const response = await this.$store.dispatch(this.action, dataOptions);
        this.fetchData = { ...response };

        this.setCacheTableData(
          {
            options: dataOptions,
            data: this.fetchData,
          },
          this.action
        );
      } catch (err) {
        errorNotification("fetch_data_failed", err, false);

        return false;
      } finally {
        this.loadingTable(false);
      }

      return true;
    },
    getQueryOptions() {
      let dataOptions = {};

      const defaultOptions = {
        page: this.tableOptions.page,
        limit: this.tableOptions.itemsPerPage,
        order_by: this.tableOptions.sortBy[0]
          ? this.tableOptions.sortBy[0]
          : "id",
        ascending: this.tableOptions.sortDesc[0] ? 0 : 1,
      };

      if (this.cleanSortableValue) {
        defaultOptions.order_by = defaultOptions.order_by.replace(
          this.cleanSortableValue,
          ""
        );
      }

      if (this.filters) {
        dataOptions = { ...defaultOptions, ...this.filters };
      } else {
        dataOptions = { ...defaultOptions };
      }
      if (String(this.search).length >= 1) {
        dataOptions.query = this.search;
      }

      if (this.selectedGroupIds.length > 0) {
        dataOptions.group_ids = this.selectedGroupIds.join(",");
      }

      return dataOptions;
    },
    load(options) {
      options === "debounce" ? this.loadDebounced() : this.loadData();
    },
    refreshTable() {
      this.selected = [];
      this.tableOptions.page = 1;
      this.loadData(true);
    },
    loadDebounced: debounce(function () {
      this.loadData();
    }, 600),
  },
};
</script>
<style lang="scss">
.custom-table {
  .custom-checkbox {
    padding: 6px;
  }

  .header-style {
    span {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: #263238;
      opacity: 0.6;
    }
  }

  .v-data-footer {
    .v-data-footer__pagination {
      margin: 0;
    }

    .v-data-footer__select .v-select {
      margin: 13px 0 13px 10px;
    }
  }
}

.custom-table
  .theme--light.v-text-field
  > .v-input__control
  > .v-input__slot:before {
  border-color: rgba(255, 255, 255, 0) !important;
}

.custom-table.theme--light.v-data-table
  tbody
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #f4e3ff;
}

.custom-table.v-data-table tbody tr.v-data-table__selected {
  background: #f4e3ff;
}

.list {
  .card-position {
    position: absolute;
    top: -42px;
  }

  .theme--light.v-sheet--outlined {
    border: none !important;
  }
}

.footer-position {
  margin-top: 4rem;
}
</style>
