export function downloadPDF(data, fileName) {
  const newBlob = new Blob([data], { type: "application/pdf" });

  // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
  } else {
    // For other browsers: create a link pointing to the ObjectURL containing the blob.
    const objUrl = window.URL.createObjectURL(newBlob);

    const link = document.createElement("a");
    link.href = objUrl;
    link.download = fileName;
    link.click();

    // For Firefox it is necessary to delay revoking the ObjectURL.
    setTimeout(() => {
      window.URL.revokeObjectURL(objUrl);
    }, 250);
  }
}
