import { mapActions } from "vuex";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";

export default {
  methods: {
    ...mapActions({
      update: "service/update",
    }),
    async updateService(object) {
      try {
        this.loadingTable(true);
        await this.update(object);
        this.loadingTable(false);
        successNotification("update_success");
      } catch (err) {
        errorNotification("error_occurred", err);
      }
    },
  },
};
