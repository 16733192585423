<template>
  <v-container class="form__container" fluid>
    <v-row>
      <v-col cols="12">
        <c-data-table
          :headers="headers"
          action="invoices/fetchInvoices"
          :search="search"
          :default-sort-by="['issue_date']"
          @downloadInvoice="download"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CDataTable from "@/components/CDataTable/CDataTable.vue";
import { downloadPDF } from "@/lib/calendesk-js-library/tools/downloadPDF";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "PaymentsInvoices",
  components: { CDataTable },
  mixins: [sharedActions],
  data() {
    return {
      downloadProgress: null,
      search: null,
      headers: [
        {
          text: this.$trans("invoice_issued_at"),
          value: "issue_date",
          class: "header-style",
          sortable: true,
        },
        {
          text: this.$trans("invoice_number"),
          value: "number",
          class: "header-style",
          sortable: true,
        },
        {
          text: this.$trans("invoice_total"),
          value: "price_gross",
          class: "header-style",
          sortable: false,
        },
        {
          text: this.$trans("invoice_description"),
          value: "description",
          class: "header-style",
          sortable: false,
        },
        {
          text: this.$trans("download_pdf"),
          value: "invoice_pdf",
          class: "header-style",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      invoices: "invoices/getInvoices",
    }),
  },
  methods: {
    ...mapActions({
      fetchInvoices: "invoices/fetchInvoices",
      downloadInvoice: "invoices/downloadInvoice",
    }),
    openPlans() {
      this.$root.$emit("openPlanView", false);
    },
    download(item) {
      pushEvent("downloadInvoice");

      this.setIsSending(true);
      this.downloadInvoice(item.id)
        .then((response) => {
          downloadPDF(response, item.number);
        })
        .finally(() => {
          this.setIsSending(false);
        });
    },
  },
};
</script>
